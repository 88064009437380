<template>
  <div class="am-vehicle-cross-selling-slider">
    <div class="am-vehicle-cross-selling-slider__inner">
      <div class="am-vehicle-cross-selling-slider__section" v-if="headline">
        <amui-headline html-tag="h3" looks-like="h6">
          {{ headline }}
        </amui-headline>
      </div>
      <div class="am-vehicle-cross-selling-slider__items-wrapper">
        <div class="am-vehicle-cross-selling-slider__items" ref="items">
          <div
            v-for="(vehicle, i) in vehicles"
            :key="i"
            class="am-vehicle-cross-selling-slider__item-wrapper"
            ref="item"
          >
            <am-vehicle-tile
              :sources="vehicle.sources"
              :fallback-source="vehicle.fallbackSource"
              :efficiency-class="vehicle.efficiencyClass"
              :efficiency-text="vehicle.efficiencyText"
              :efficiency-text-reference-number="
                vehicle.efficiencyTextReferenceNumber
              "
              :title="vehicle.title"
              :is-favorite="vehicle.isFavorite"
              :rate="vehicle.rateRaw"
              :customer-highlights="vehicle.customerHighlights"
              :features="vehicle.features"
              :vehicle-url="vehicle.url"
              :price="vehicle.price"
              :usage-type="vehicle.usageType"
              @view="$emit('view', vehicle.id)"
              @favour="$emit('favor', vehicle.id)"
              class="am-vehicle-cross-selling-slider__item"
            ></am-vehicle-tile>
          </div>
        </div>
      </div>
      <div class="am-vehicle-cross-selling-slider__section">
        <div class="am-vehicle-cross-selling-slider__navigation">
          <div>
            <amui-button
              type="primary"
              flat
              icon="arrow-slide-left"
              htmlTag="button"
              :disabled="hasDisabledPreviousButton"
              :aria-label="navigateBackwardLabel"
              @click.native="onClickPrevious"
            ></amui-button>
            <amui-button
              type="primary"
              flat
              icon="arrow-slide-right"
              htmlTag="button"
              :disabled="hasDisabledNextButton"
              :aria-label="navigateForwardLabel"
              @click.native="onClickNext"
            ></amui-button>
          </div>
          <a
            :href="searchHref"
            class="am-vehicle-cross-selling-slider__search-link"
            @click.prevent="$emit('search')"
            >{{ searchLabel }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AmVehicleTile from '@/shared/components/list/vehicle-tile/vehicle-tile'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'

export default {
  components: {
    AmVehicleTile,
    AmuiHeadline,
    AmuiButton
  },

  props: {
    vehicles: {
      type: Array,
      required: true
    },
    headline: {
      type: String,
      required: false,
      default: null
    },
    searchLabel: {
      type: String,
      required: true
    },
    searchHref: {
      type: String,
      required: true
    },
    navigateForwardLabel: {
      type: String,
      required: true
    },
    navigateBackwardLabel: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      hasDisabledPreviousButton: true,
      hasDisabledNextButton: false,
      resizeObserverInstance: null
    }
  },

  watch: {
    vehicles() {
      this.handleSliderState(this.$refs.items)
    }
  },

  mounted() {
    this.$refs.items.addEventListener('scroll', this.onScroll)
    this.initResizeObserver()
    this.handleSliderState(this.$refs.items)
  },

  beforeDestroy() {
    this.$refs.items.removeEventListener('scroll', this.onScroll)
    this.resizeObserverInstance && this.resizeObserverInstance.disconnect()
  },

  methods: {
    initResizeObserver() {
      this.resizeObserverInstance = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            this.handleSliderState(this.$refs.items)
          }
        }
      })
      this.resizeObserverInstance.observe(this.$el)
    },
    getItemWidth() {
      return this.$refs.item[0].offsetWidth
    },
    onClickPrevious() {
      this.$refs.items.scrollBy({
        left: this.getItemWidth() * -1,
        behavior: 'smooth'
      })
    },
    onClickNext() {
      this.$refs.items.scrollBy({
        left: this.getItemWidth(),
        behavior: 'smooth'
      })
    },
    onScroll(e) {
      this.handleSliderState(e.target)
    },
    handleSliderState(el) {
      this.hasDisabledPreviousButton = el.scrollLeft === 0
      this.hasDisabledNextButton =
        el.scrollLeft + el.offsetWidth >= el.scrollWidth
    }
  }
}
</script>
